import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
    idUsuario: number | null;
    usuario: string;
    isLogged: boolean;
    estado: string | null;
    timestamp: number | null;
    perfil: string | null;
}

const initialState: AuthState = {
    idUsuario: null,
    usuario: '',
    isLogged: false,
    estado: '',
    timestamp: null,
    perfil: ''
};

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, {payload}: PayloadAction<{idUsuario: number; usuario: string; estado: string, isLogged: boolean, perfil: string}>){
            localStorage.setItem("auth", JSON.stringify(payload));
            
            return {...state, isLogged: payload.isLogged, usuario: payload.usuario, estado: payload.estado, idUsuario: payload.idUsuario, perfil: payload.perfil}
        },
        logout(state) {
            localStorage.removeItem("auth");
            localStorage.removeItem("token");
            return{...state, isLogged: false, usuario: '', estado: '', idUsuario: null, timestamp: null, perfil: '' }
        },
        atualizaToken(state, {payload}: PayloadAction<{timestamp: number}>){
            localStorage.setItem("token", JSON.stringify(payload));
            return {...state, timestamp: payload.timestamp }
        }
    }
})

export const {login, logout, atualizaToken} = slice.actions

export const selectAuth = (state: { auth: AuthState }) => state.auth

export default slice.reducer