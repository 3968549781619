import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { atualizaToken, logout } from '../redux/userSlice';
import { useToast } from '@chakra-ui/react';

export const useAuthPerfil = () => {
    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const auth = authString ? JSON.parse(authString) : null;
    const navigate = useNavigate();


    useEffect(() => {
        if(auth?.perfil === "ACOMPANHAMENTO") {
            navigate("/");
        }
    }, [auth, navigate]);

    return auth;
};