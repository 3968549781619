import React, {useState } from 'react';
import { Box, Flex, Text, Tooltip, Link, Image, Heading, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react'
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import MenuLateral from './menuLateral';
import { getDescricao, getFlexao } from '../../utils/utils';
import { estados } from '../../constants/static-data';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';


const MenuPrincipal: React.FC = () => {

    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const auth = authString ? JSON.parse(authString) : null;

    const [state, setState] = useState(auth?.estado);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { onOpen, isOpen, onClose } = useDisclosure();


    const handleLogout = () => {
        dispatch(logout());
        setAuthString(localStorage.getItem('auth'));
        navigate("/login");
    }

    return (
        <>
            <Flex
                as="nav"
                p="1.2em"
                bg="gray.10"
                position="relative"
                flexDir="row"
                align="center"
                justifyContent="space-between"
                w={['95%', '90%', '90%', '90%']}
                marginInline={'5em'}
            >
                <Flex
                    id="div-principalmenu"
                    flexDir="row"
                    align="center"
                >
                    <MenuLateral isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
                    <Text
                        mr="30px"
                        padding="8px 12px"
                        borderRadius="60%"
                        bg="#007B2F"
                        color='#fff'
                        fontWeight="600"
                        letterSpacing="1px"
                        transition="all .2s"
                        onClick={onOpen}
                        _hover={{
                            backgroundColor: '#8BC34A',
                            border: '1px solid green.500',
                            textDecoration: 'none'
                        }}>
                        <MenuOutlined />
                    </Text>
                    <Link
                        id="logo-esdp-menu"
                        borderRadius="10px"
                        _focus={{ boxShadow: 'none', backgroundColor: '#8EB63E20' }}
                        href="/meu-pai-tem-nome"
                        w="30%"
                        zIndex="100"
                    >
                        <Image
                            role="image"
                            id="img-esdp-logo"
                            src="/images/dpe-logo.svg"
                            alt="Logotipo da Escola Superior da DPEGO"
                            width="100%"
                        />
                    </Link>
                </Flex>
                <Flex
                    id="div-principalmenu"
                    flexDir="row"
                    align="center"
                    justifyContent={'end'}
                    w={'100%'}>
                    <Text
                        mr={'2%'}
                        color={"#8BC34A"}
                        fontSize={'18px'}
                        fontWeight='400'
                    >
                        Olá, seja bem vindo, Estado {getFlexao(state, estados)} {getDescricao(state, estados)}!
                    </Text>
                    <Tooltip label="Sair">
                        <Text fontSize={'32px'} color="#016A61" onClick={() => handleLogout()} >
                            <LogoutIcon />
                        </Text>
                    </Tooltip>
                </Flex>
            </Flex>
        </>
    );
};
export default MenuPrincipal;