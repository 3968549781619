import React, { useEffect, useState } from "react";
import type { FormProps } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import { Button, Form, Input, Modal } from 'antd';
import { Divider, Flex, Image, Text, useToast } from "@chakra-ui/react";
import MenuPrincipal from "../../components/Menu";
import Footer from "../../components/Footer";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";
import { useAtualizaToken } from "../../hooks/useAtualizaToken";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getDescricao, getFlexao } from "../../utils/utils";
import { estados } from "../../constants/static-data";
import { useAuthPerfil } from "../../hooks/useAuthPerfil";



type FieldType = {
    atendimentos: number;
    recPatComDna: number
    recPatSemDna: number;
    recPatMatSocioAfetivaComMulti: number;
    recPatMatSocioAfetivaSemMulti: number;
    recMatPostMortem: number;
    recPatPostMortem: number;
    dnaAgendados: number;
    dnaRealizados: number;
    dnaPositivos: number;
    pedidosHml: number;
    acordosRealizados: number;
    constaRegistroGenitor: number;
    naoConstaRegistroGenitor: number;
};

const inputStyle: React.CSSProperties = {
    width: '18rem'
}

const bodyStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    background: 'url("/images/backgroundIcon.svg") right bottom no-repeat #F5F5F5',
    minHeight: '100vh'
};

const boxFormStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: '20px',
    boxShadow: '1px 1px 10px #898787',
    border: '1px #fff'
}

export default function ColetaMeuPaiTemNome() {
    useAuthRedirect();
    useAtualizaToken();
    useAuthPerfil();
    const toast = useToast();
    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const auth = authString ? JSON.parse(authString) : null;
    const { confirm } = Modal;
    const [form] = Form.useForm();


    const showConfirm = async (values: any) => {
        confirm({
            title: 'Deseja atualizar os dados enviados? ',
            icon: <ExclamationCircleFilled color="#007B2F" />,
            content: `Os dados da coleta do Estado ${getFlexao(auth?.estado, estados)} ${getDescricao(auth?.estado, estados)} já foram enviados anteriormente, deseja atualizá-los? `,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            async onOk() {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/meupaitemnome/edit`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            atendimentos: values.atendimentos,
                            recPatComDna: values.recPatComDna,
                            recPatSemDna: values.recPatSemDna,
                            recPatMatSocioAfetivaComMulti: values.recPatMatSocioAfetivaComMulti,
                            recPatMatSocioAfetivaSemMulti: values.recPatMatSocioAfetivaSemMulti,
                            recMatPostMortem: values.recMatPostMortem,
                            recPatPostMortem: values.recPatPostMortem,
                            dnaAgendados: values.dnaAgendados,
                            dnaRealizados: values.dnaRealizados,
                            dnaPositivos: values.dnaPositivos,
                            pedidosHml: values.pedidosHml,
                            acordosRealizados: values.acordosRealizados,
                            constaRegistroGenitor: values.constaRegistroGenitor,
                            naoConstaRegistroGenitor: values.naoConstaRegistroGenitor,
                            usuarioId: auth.idUsuario,
                            uf: auth.estado
                        }),
                    });

                    toast({
                        title: 'Dados Editados com Sucesso!',
                        description: `Os dados da coleta do Estado ${getFlexao(auth?.estado, estados)} ${getDescricao(auth?.estado, estados)}, foram editados com sucesso, acompanhe os resultados na Aba Painel de Resultados!`,
                        status: 'success',
                        duration: 6000,
                        isClosable: true
                    });
                    form.resetFields();

                } catch (error) {
                    toast({
                        title: 'Erro ao editar os dados!',
                        description: `Entre em contato com nossos canais de suporte técnico através do número (62) 3157-1087 ou pelo email dps@defensoria.go.def.br`,
                        status: 'info',
                        duration: 6000,
                        isClosable: true
                    });
                    console.error('Erro na requisição:', error);
                }
            },
            onCancel() {
                toast({
                    title: 'Envio cancelado!',
                    status: 'info',
                    duration: 4000,
                    isClosable: true
                });
                form.resetFields();
            },
        });
    };

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const verificaDados = await fetch(`${process.env.REACT_APP_BASE_URL}/meupaitemnome/verifica-dados?uf=${auth.estado}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await verificaDados.json();
            if (data) {
                await showConfirm(values);
            }
            else {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/meupaitemnome/save`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            atendimentos: values.atendimentos,
                            recPatComDna: values.recPatComDna,
                            recPatSemDna: values.recPatSemDna,
                            recPatMatSocioAfetivaComMulti: values.recPatMatSocioAfetivaComMulti,
                            recPatMatSocioAfetivaSemMulti: values.recPatMatSocioAfetivaSemMulti,
                            recMatPostMortem: values.recMatPostMortem,
                            recPatPostMortem: values.recPatPostMortem,
                            dnaAgendados: values.dnaAgendados,
                            dnaRealizados: values.dnaRealizados,
                            dnaPositivos: values.dnaPositivos,
                            pedidosHml: values.pedidosHml,
                            acordosRealizados: values.acordosRealizados,
                            constaRegistroGenitor: values.constaRegistroGenitor,
                            naoConstaRegistroGenitor: values.naoConstaRegistroGenitor,
                            usuarioId: auth.idUsuario,
                            uf: auth.estado
                        }),
                    });

                    toast({
                        title: 'Dados Enviados com Sucesso!',
                        description: `Os dados da coleta do Estado ${getFlexao(auth?.estado, estados)} ${getDescricao(auth?.estado, estados)}, foram enviados com sucesso, acompanhe os resultados na Aba Painel de Resultados!`,
                        status: 'success',
                        duration: 6000,
                        isClosable: true
                    });
                    form.resetFields();

                } catch (error) {
                    toast({
                        title: 'Erro ao enviar os dados!',
                        description: `Entre em contato com nossos canais de suporte técnico através do número (62) 3157-1087 ou pelo email dps@defensoria.go.def.br`,
                        status: 'info',
                        duration: 6000,
                        isClosable: true
                    });
                    console.error('Erro na requisição:', error);
                }
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        }

    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <MenuPrincipal />
            <Flex style={bodyStyle}>

                <Flex display={'block'}>
                    <Flex style={boxFormStyle}
                        display={'block'}
                        mt={'5%'}
                        mb={'5%'}
                        p={{ base: 30, sm: 50, md: 30, lg: 30 }}
                        w={{ base: '80%', sm: 'auto', md: 'auto', lg: 'auto' }}
                    >
                        <Flex marginBottom={'1em'} display={'block'}>
                            <Text
                                style={{
                                    fontSize: "1.5rem",
                                    marginBottom: '1em'
                                }}
                                display={'flex'}
                            >
                                <Image width={10} style={{ margin: '0 5px 0 0' }} src='/images/favicon.png' />
                                Coleta de dados: Programa Meu Pai tem Nome
                            </Text>
                            <Divider></Divider>
                        </Flex>

                        <Form
                            name="basic"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 24 }}
                            labelWrap
                            //style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            form={form}
                            layout="horizontal"
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >

                            <Flex alignContent={'center'} w={'100%'} textAlign={'center'} mb={'1em'}>
                                <Text fontSize={'1rem'}>Preencha os dados abaixo:</Text>
                            </Flex>


                            <Form.Item<FieldType>
                                label="N° de atendimentos"
                                name="atendimentos"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimentos com DNA"
                                name="recPatComDna"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimentos sem DNA"
                                name="recPatSemDna"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimento de paternidade/maternidade socioafetiva com multiparentalidade"
                                name="recPatMatSocioAfetivaComMulti"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimento de paternidade/maternidade socioafetiva sem multiparentalidade"
                                name="recPatMatSocioAfetivaSemMulti"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimento de paternidade post mortem"
                                name="recPatPostMortem"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de reconhecimento de maternidade post mortem"
                                name="recMatPostMortem"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de atendimentos que já constava nome do genitor"
                                name="constaRegistroGenitor"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de atendimentos que não constava nome do genitor"
                                name="naoConstaRegistroGenitor"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de exames de DNA agendados"
                                name="dnaAgendados"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de exames de DNA realizados"
                                name="dnaRealizados"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de exames de DNA positivo"
                                name="dnaPositivos"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de pedidos de homologação"
                                name="pedidosHml"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                label="N° de acordos realizados"
                                name="acordosRealizados"
                                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                            >
                                <Input type="number" style={inputStyle} placeholder='Digite aqui...' />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                <Button type="primary" htmlType="submit"
                                    style={{ background: '#007B2F', padding: '0 30px' }}>
                                    Enviar dados
                                </Button>
                            </Form.Item>
                        </Form>
                    </Flex>
                </Flex>
            </Flex>
            <Footer />
        </>
    );

} 
