
import { PhoneFilled } from '@ant-design/icons';
import { Flex, Image, Link, Text } from '@chakra-ui/react';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { PhoneIcon } from '@chakra-ui/icons'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const Footer: React.FC = () => {
    return (
        <>
            <Flex display={'block'} justifyContent={'center'} w={'100%'} bg={'#ECECEC'}>
                <Flex w={'100%'} justifyContent={'center'} p={'1%'}>
                    <Text fontSize={'17px'} fontWeight={400}>Caso tenha alguma dúvida, utilize dos nossos canais de atendimento:</Text>
                </Flex>
                <Flex marginBlock={'1%'}>
                    <Row style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                        <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link
                                id="logo-esdp-menu"
                                borderRadius="10px"
                                _focus={{ boxShadow: 'none', backgroundColor: '#8EB63E20' }}
                                href="/meu-pai-tem-nome"
                                w={'100%'}
                                textAlign={'center'}
                                display="flex"
                                justifyContent="center"
                                mb={3}
                            >
                                <Image
                                    role="image"
                                    id="img-esdp-logo"
                                    src="/images/dpe-logo.svg"
                                    alt="Logotipo da Escola Superior da DPEGO"
                                    width="35%"
                                />
                            </Link>
                        </Col>
                        <Col span={8} style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
                            <Text
                                fontSize={'22px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                fontWeight={500}
                                color={'#333333'}
                                textAlign="center">
                                <LocalPhoneIcon style={{ fontSize: '21px', marginRight: '5px', marginTop: '3px' }} /> Contato
                            </Text>
                            <Text
                                fontSize={'17px'}
                                fontWeight={400}
                                color={'#565656'}>
                                (62) 3157-1087
                            </Text>
                            <Text
                                fontSize={'17px'}
                                fontWeight={400}
                                color={'#565656'}>
                                dps@defensoria.go.def.br
                            </Text>
                        </Col>
                        <Col span={8} style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
                            <Text
                                fontSize={'22px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                fontWeight={500}
                                color={'#333333'}
                                textAlign="center">
                                <WatchLaterIcon style={{ fontSize: '18px', marginRight: '5px', marginTop: '3px' }} /> Horário de Funcionamento
                            </Text>
                            <Text
                                fontSize={'17px'}
                                fontWeight={400}
                                color={'#565656'}>
                                8 às 18 horas
                            </Text>
                            <Text
                                fontSize={'17px'}
                                fontWeight={400}
                                color={'#565656'}>
                                De segunda a sexta-feira
                            </Text>
                        </Col>
                    </Row>
                </Flex>
                <Flex justifyContent={'center'} bg="#007B2F" color={'#fff'} p={2}>
                    <Text fontWeight={300}>© Copyright {new Date().getFullYear()}. Todos os direitos reservados. Desenvolvido pela Defensoria Pública do Estado de Goiás.</Text>
                </Flex>
            </Flex>
        </>
    );
}

export default Footer;