import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Flex } from 'antd';
import { BrowserRouter, Route, Routes, redirect } from 'react-router-dom';
import ColetaMeuPaiTemNome from './pages/coleta-meu-pai-tem-nome/coleta-meu-pai-tem-nome';
import login from './pages/login/login';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import Home from './pages/home/home';
import Resultados from './pages/resultados/resultados';

function App() {


  return (
    <>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter basename="/meu-pai-tem-nome">
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/login" Component={login} />
            <Route
              path="/coleta"
              Component={ColetaMeuPaiTemNome}
            /> 
            <Route
              path="/resultados"
              Component={Resultados}
            /> 
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
      </Provider>
    </>
  );

}

export default App;
