import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { atualizaToken } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";

export const useAtualizaToken = () => {
    const [tokenString, setTokenString] = useState(localStorage.getItem('token'))
    const token = tokenString ? JSON.parse(tokenString) : null;
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
          const valorTimestamp = {
            timestamp: new Date().getTime()
          }
          dispatch(atualizaToken(valorTimestamp))
          setTokenString(localStorage.getItem('token'));
        };
    
        // Adiciona o event listener de clique no documento
        document.addEventListener('click', handleClick);
    
        // Função de limpeza para remover o event listener quando o componente for desmontado
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, []); 
}