import React, { useState } from 'react';
import {
    Drawer,
    DrawerContent,
    DrawerBody,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Link,
    DrawerCloseButton,
    DrawerHeader
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

interface MenuLateralProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
}

const MenuLateral: React.FC<MenuLateralProps> = (props) => {
    const { isOpen, onClose } = props;
    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const auth = authString ? JSON.parse(authString) : null;



    return (
        <>
            <Drawer placement={'left'} onClose={onClose} isOpen={isOpen} size={'md'}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ fontSize: '24px', color: '#8BC34A' }} >Menu</DrawerHeader>
                    <DrawerBody bg="gray.10"
                        style={{
                            padding: 0,
                            background: "rgba(255, 255, 255, 0.7)",
                            backdropFilter: "blur(2px)"
                        }}
                    >
                        {(auth?.perfil !== "ACOMPANHAMENTO")  && (
                            <Box flex='1'
                                style={{
                                    display: "flex",
                                    paddingLeft: "15px",
                                    transition: '0.3s'
                                }}
                                _hover={{
                                    backgroundColor: '#F5F5F5'
                                }}
                                alignSelf={'center'}
                                alignItems={"center"}
                            >
                                <PostAddIcon
                                    style={{
                                        minWidth: '30px',
                                        minHeight: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'space-around',
                                        color: '#016A61'
                                    }} />
                                <Link href={"/meu-pai-tem-nome/coleta"} target="_self"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                        color: '#016A61',
                                        padding: '20px'
                                    }}
                                    _hover={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Coleta de dados: Programa Meu Pai tem Nome
                                </Link>
                            </Box>
                        )}
                        <Box flex='1'
                            style={{
                                display: "flex",
                                paddingLeft: "15px",
                                transition: '0.3s'
                            }}
                            _hover={{
                                backgroundColor: '#F5F5F5'
                            }}
                            alignSelf={'center'}
                            alignItems={"center"}
                        >
                            <LeaderboardIcon
                                style={{
                                    minWidth: '30px',
                                    minHeight: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'space-around',
                                    color: '#016A61'
                                }} />
                            <Link href={"/meu-pai-tem-nome/resultados"} target="_self"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '18px',
                                    color: '#016A61',
                                    padding: '20px'
                                }}
                                _hover={{
                                    textDecoration: 'none'
                                }}
                            >
                                Painel de Resultados
                            </Link>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer >
        </>
    );
};

export default MenuLateral;
