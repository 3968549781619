import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import { Button, Form, Input } from 'antd';
import { Box, Divider, Flex, Grid, Heading, Image, Link, Text, Tooltip } from "@chakra-ui/react";
import MenuPrincipal from "../../components/Menu";
import Footer from "../../components/Footer";
import './home.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useAuthRedirect } from "../../hooks/useAuthRedirect";
import { useAtualizaToken } from "../../hooks/useAtualizaToken";


const bodyStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    background: 'url("/images/backgroundIcon.svg") right bottom no-repeat #F5F5F5',
};

export default function Home() {
    useAuthRedirect();
    useAtualizaToken();
    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const auth = authString ? JSON.parse(authString) : null;

    return (
        <>
            <MenuPrincipal />
            <Flex
                style={bodyStyle}
                className="bodyHome"
            >
                <Flex
                    flexDir="column"
                    pt={5}>
                    <Grid
                        templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)'
                        ]}
                        gap={['30px', '30px', '30px', '30px']}
                    >
                        {auth?.perfil !== 'ACOMPANHAMENTO' && (
                            <Tooltip
                                placement="top"
                                bg="#F9F9F9"
                                color="black"
                            >
                                <Link
                                    className="acesso-rapido-item"
                                    href="/meu-pai-tem-nome/coleta"
                                    target="_self"
                                    display="block"
                                    background={'rgba(255, 255, 255, 1)'}
                                    style={{
                                        borderRadius: 10,
                                        border: "1px solid #FFF",
                                        padding: "5% 1%",
                                        width: '50rem'
                                    }}
                                    _hover={{
                                        background: 'rgba(247, 247, 247, 1)',
                                        textDecoration: 'none',
                                        outline: 'none'
                                    }}
                                    _focus={{
                                        outline: 'none'
                                    }}
                                >
                                    <Flex
                                        flexDir="column"
                                        textAlign="center"
                                        alignItems="center"
                                    >
                                        <PostAddIcon
                                            style={{
                                                minWidth: '60px',
                                                minHeight: "60px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: 'space-around',
                                                color: '#016A61',
                                                marginBottom: '1%'
                                            }} />
                                        <Heading
                                            fontSize="20px"
                                            fontWeight="700"
                                            textAlign="center"
                                            color="#016A61"
                                            w={['95%', '95%', '90%', '85%']}
                                            textTransform="uppercase"
                                        >
                                            Coleta de dados: Programa Meu Pai tem Nome
                                        </Heading>
                                    </Flex>
                                </Link>
                            </Tooltip>
                        )}
                        <Tooltip
                            placement="top"
                            bg="#F9F9F9"
                            color="black"
                        >
                            <Link
                                className="acesso-rapido-item"
                                href="/meu-pai-tem-nome/resultados"
                                target="_self"
                                display="block"
                                background={'rgba(255, 255, 255, 1)'}
                                style={{
                                    borderRadius: 10,
                                    border: "1px solid #FFF",
                                    padding: "5% 1%",
                                    width: '50rem'
                                }}
                                _hover={{
                                    background: 'rgba(247, 247, 247, 1)',
                                    textDecoration: 'none',
                                    outline: 'none'
                                }}
                                _focus={{
                                    outline: 'none'
                                }}
                            >
                                <Flex flexDir="column" textAlign="center" alignItems="center">
                                    <LeaderboardIcon
                                        style={{
                                            minWidth: '55px',
                                            minHeight: "55px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-around',
                                            color: '#016A61',
                                            marginBottom: '1%'
                                        }} />
                                    <Heading
                                        fontSize="20px"
                                        fontWeight="700"
                                        textAlign="center"
                                        color="#016A61"
                                        w={['95%', '95%', '90%', '85%']}
                                        textTransform="uppercase"

                                    >
                                        Painel de Resultados
                                    </Heading>
                                </Flex>
                            </Link>
                        </Tooltip>
                    </Grid>
                </Flex>
            </Flex>
            <Footer />
        </>
    );

} 
