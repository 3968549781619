import React, { useEffect } from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { Divider, Flex, Image, Link, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { atualizaToken, login } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

type FieldType = {
    username: string;
    password: string;
};

type AuthState = {
    usuario: string;
    isLogged: boolean;
    estado: string;
    idUsuario: number;
    perfil: string;
}

const bodyStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    background: 'url("/images/backgroundIcon.svg") right bottom no-repeat #F5F5F5',
    minHeight: '100vh'
};

const boxLoginStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: '20px',
    boxShadow: '1px 1px 10px #898787',
    border: '1px #fff'
}

const boxLoginDataStyle: React.CSSProperties = {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: 'block',
    backgroundColor: '007B2F'
}

const inputStyle: React.CSSProperties = {
    width: '18rem'
}

const textStyle: React.CSSProperties = {
    fontSize: '1.5rem'
}


export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();


    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/usuarios/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    usuario: values.username,
                    senha: values.password
                }),
            });
    
            if (!response.ok) {

                toast({
                    title: 'Erro ao realizar o login',
                    description: 'Usuário ou senha inválido, tente novamente!',
                    status: 'warning',
                    duration: 6000,
                    isClosable: true
                  });

                console.log('Erro na resposta:', response);
                return;
            }
    
            const data = await response.json();
            
            const valorAuth: AuthState = {
                usuario: data.usuario,
                estado: data.uf,
                idUsuario: data.id,
                isLogged: true,
                perfil: data.perfil

            };
    
            const valorTimestamp = {
                timestamp: new Date().getTime()
            };
    
            dispatch(login(valorAuth));
            dispatch(atualizaToken(valorTimestamp));
            navigate("/");

        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Flex style={bodyStyle}>
                <Flex style={boxLoginStyle}
                    display={{ base: 'block', sm: 'block', md: 'block', lg: 'flex' }}
                    p={{ base: 30, sm: 50, md: 30, lg: 50 }}
                    w={{ base: '80%', sm: 'auto', md: 'auto', lg: 'auto' }}
                >
                    <Flex style={boxLoginDataStyle} >
                        <Flex align='center' justify='center' style={{ margin: 15 }}>
                            <Text style={textStyle}>
                                Login
                            </Text>
                        </Flex>
                        <Flex justify='center'>
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                style={{ maxWidth: 600 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >

                                <Form.Item<FieldType>
                                    name="username"
                                    rules={[{ required: true, message: 'Por favor, insira o usuário!' }]}
                                >
                                    <Input style={inputStyle} placeholder='Usuário' />
                                </Form.Item>

                                <Form.Item<FieldType>
                                    name="password"
                                    rules={[{ required: true, message: 'Por favor, insira a senha!' }]}
                                >
                                    <Input.Password style={inputStyle} placeholder='Senha' />
                                </Form.Item>

                                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <Button type="primary" htmlType="submit"
                                        style={{ background: '#007B2F', padding: '0 30px' }}>
                                        Entrar
                                    </Button>
                                </Form.Item>
                            </Form>

                        </Flex>
                    </Flex>
                    <Flex justify='center'>
                        <Flex
                            style={{ height: '12rem', margin: '0 1rem 0 2rem' }}
                            display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}>
                            <Divider orientation='vertical' />
                        </Flex>
                        <Flex>
                            <Image width={200} style={{ margin: '0 15px' }} src='/images/dpe-logo.svg' />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
