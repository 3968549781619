export const estados = [
  { cod: 'AC', descricao: 'Acre', flexao: 'do' },
  { cod: 'AL', descricao: 'Alagoas', flexao: 'de' },
  { cod: 'AM', descricao: 'Amazonas', flexao: 'do' },
  { cod: 'AP', descricao: 'Amapá', flexao: 'do' },
  { cod: 'BA', descricao: 'Bahia', flexao: 'da' },
  { cod: 'CE', descricao: 'Ceará', flexao: 'do' },
  { cod: 'DF', descricao: 'Distrito Federal', flexao: 'do' },
  { cod: 'ES', descricao: 'Espírito Santo', flexao: 'do' },
  { cod: 'GO', descricao: 'Goiás', flexao: 'de' },
  { cod: 'MA', descricao: 'Maranhão', flexao: 'do' },
  { cod: 'MG', descricao: 'Minas Gerais', flexao: 'de' },
  { cod: 'MS', descricao: 'Mato Grosso do Sul', flexao: 'do' },
  { cod: 'MT', descricao: 'Mato Grosso', flexao: 'do' },
  { cod: 'PA', descricao: 'Pará', flexao: 'do' },
  { cod: 'PB', descricao: 'Paraíba', flexao: 'da' },
  { cod: 'PE', descricao: 'Pernambuco', flexao: 'de' },
  { cod: 'PI', descricao: 'Piauí', flexao: 'do' },
  { cod: 'PR', descricao: 'Paraná', flexao: 'do' },
  { cod: 'RJ', descricao: 'Rio de Janeiro', flexao: 'do' },
  { cod: 'RN', descricao: 'Rio Grande do Norte', flexao: 'do' },
  { cod: 'RO', descricao: 'Rondônia', flexao: 'de' },
  { cod: 'RR', descricao: 'Roraima', flexao: 'de' },
  { cod: 'RS', descricao: 'Rio Grande do Sul', flexao: 'do' },
  { cod: 'SC', descricao: 'Santa Catarina', flexao: 'de' },
  { cod: 'SE', descricao: 'Sergipe', flexao: 'de' },
  { cod: 'SP', descricao: 'São Paulo', flexao: 'de' },
  { cod: 'TO', descricao: 'Tocantins', flexao: 'do' }
];