import React from "react";
import { Box, Button, Divider, Flex, Grid, Heading, Image, Link, Text, Tooltip, useToast } from "@chakra-ui/react";
import MenuPrincipal from "../../components/Menu";
import Footer from "../../components/Footer";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";
import { useAtualizaToken } from "../../hooks/useAtualizaToken";
import { LinkIcon } from "@chakra-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";


const bodyStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    // justifyContent: "center",
    // alignContent: "center",
    // alignItems: "center",
    // background: 'url("/images/backgroundIcon.svg") right bottom no-repeat #F5F5F5',
};

export default function Resultados() {
    useAuthRedirect();
    useAtualizaToken();
    const toast = useToast();

    async function toastCopia() {
        toast({
            title: 'Link Copiado com Sucesso',
            description: 'O link do painel foi copiado com sucesso.',
            status: 'success',
            duration: 6000,
            isClosable: true
        });

    }


    return (
        <>
            <MenuPrincipal />
            <Flex justifyContent={'end'} p={3} ml={10}>
                <CopyToClipboard text={`${process.env.REACT_APP_URL_PAINEL}`}>
                    <Button
                        backgroundColor={'#007B2F'}
                        color={'#fff'}
                        _hover={{
                            background: '#006928',
                            textDecoration: 'none'
                        }} >
                        <Link
                            onClick={() => toastCopia()}
                            display={'flex'}
                            alignItems={'center'}
                            _hover={{
                                textDecoration: 'none'
                            }}  >
                            <LinkIcon fontSize={'100%'} mr={2} />
                            <Text>Copiar link do Painel</Text>
                        </Link>
                    </Button>
                </CopyToClipboard>
            </Flex>

            <div>
                <iframe
                    src={process.env.REACT_APP_URL_PAINEL}
                    width="100%"
                    height="1150px"
                ></iframe>
            </div>
            <Footer />
        </>
    );

} 
