import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { atualizaToken, logout } from '../redux/userSlice';
import { useToast } from '@chakra-ui/react';

export const useAuthRedirect = () => {
    const [authString, setAuthString] = useState(localStorage.getItem('auth'));
    const [tokenString, setTokenString] = useState(localStorage.getItem('token'))
    const token = tokenString ? JSON.parse(tokenString) : null;
    const auth = authString ? JSON.parse(authString) : null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const maxToken = process.env.REACT_APP_MAX_TOKEN;


    useEffect(() => {
        setTokenString(localStorage.getItem('token'))
        const dataHoraAtual = new Date().getTime();
        const diferencaDeHoras = dataHoraAtual - token?.timestamp;
        if (diferencaDeHoras > Number(maxToken)) {
            dispatch(logout());
            setAuthString(localStorage.getItem('auth'));
        }
        if (!auth?.isLogged || auth == null) {
            toast({
                title: 'Sessão expirada',
                description: 'Sua sessão expirou, realize o login novamente!',
                status: 'info',
                duration: 6000,
                isClosable: true
              });
            navigate("/login");
        }
    }, [auth, navigate]);

    return auth;
};